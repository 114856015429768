.lottie {
    margin: 0 !important;
}

.steps {
    height: 80px;
    background-color: white;
    padding: 20px;
    margin: 30px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.cred-card {
    margin: 30px 15rem;
}

.greet {
    font-size: 22px;
    text-align: center;
}

.greet-cred {
    font-size: 16px;
    text-align: center;
    margin-top: 1rem;
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px;
    justify-content: center;
}

.card {
    width: 280px;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    margin: 20px;
    border: 1px solid #f2f3f4 !important;
}

.logo {
    width: 90px;
    height: 90px;
    margin-top: 30px;
    box-shadow: 0px 0px 5px #c7c5c5;
    border-radius: 50px;
}

.swName {
    margin-top: 20px !important;
    font-size: 18px;
}

.desp {
    margin-top: 20px !important;
    padding: 0 10px !important;
    font-size: 14px;
    text-align: center;
}

.btns {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px !important;
    justify-content: space-around;
    align-items: center;
}

.lmbtn, .here-link {
    color: #2e61de;
    cursor: pointer;
    font-size: 14px;
}

.lmbtn:hover, .here-link:hover {
    text-decoration: underline;
    color: #2e61de;
}

.conn-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.company-logo {
    width: auto;
    height: 90px;
    border-radius: 40px;
}

.text-block {
    margin: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.text-1 {
    width: 700px;
    font-size: 20px;
    text-align: center;
    margin-top: 1rem;
}

.text-2 {
    font-size: 18px;
    margin-top: 20px;
    margin-top: 2rem;
    text-align: center;
}

.pass {
    font-size: 20px;
}

.here-link {
    font-size: 18px;
    font-weight: 500;
}

.text-3 {
    font-size: 18px;
    width: 710px;
    font-weight: 500;
    margin-top: 25px;
    text-align: center;
}

.step-3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;

}

.success-text {
    font-size: 30px;
}

.dash-btn {
    font-size: 22px;
    color: #2e61de;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.right-icon {
    margin-left: 6px;
    margin-top: 2px;
}

.dash-btn:hover {
    cursor: pointer;
}