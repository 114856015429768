* {
  margin: 0;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.epos-chat {
  background: transparent;
  border: none;
  font-weight: 600;
  color: #2E61DE;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
}

.reset-icon {
  margin-right: 14px;
  font-size: 24px;
  cursor: pointer;
}
